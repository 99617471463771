import Model from './Model'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export default class Report extends Model {
  resource() {
    return 'admin/reports'
  }

  get reportedRecord() {
    let reported = 'test'

    switch (this.report_type) {
      case 'users':
      default:
        reported = this.reportable.full_name
    }

    return reported
  }

  get addedDate() {
    return dayjs.utc(this.created_at).local().format('MMM DD, YYYY hh:mm A')
  }
}
